import { createState, useState } from "@hookstate/core";
import axios from "axios";
import { baseUrl } from "../baseUrl";
import { store, useDispatch } from "../Store";

const state = createState({
  secretKeys: {},
  loading: true,
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      // Add your existing case statements here

      case "INSERT_SECRET_KEY":
        return s.merge({
          secretKeys: {
            ...s.secretKeys,
            [action.data.name]: action.data.value,
          },
          loading: false,
        });

      // Add additional cases as necessary
      default:
        // Handle any other actions or return the current state by default
        return s;
    }
  });
});

export const accessSecurityState = () => state;

export const useSecurityState = () => useState(state);

export const SecurityService = {
  getSecretKey: async (payload) => {
    try {
      const dispatch = useDispatch();
      const currentState = state.get();

      if (currentState.secretKeys[payload.name]) {
        return currentState.secretKeys[payload.name];
      }

      const { data } = await axios.get(`${baseUrl}api/security/getKey`, {
        params: {
          name: payload.name,
        },
      });
      dispatch(SecurityAction.insertKey(data.variable, "INSERT_SECRET_KEY"));

      return data.variable;
    } catch (error) {
      console.error(error);
      // Capture sentry error
    }
  },
  getPhylloToken: async () => {
    const dispatch = useDispatch();
    const { data } = await axios.get(`${baseUrl}api/security/getPhylloToken`);
    dispatch(SecurityAction.insertKey(data.variable, "INSERT_SECRET_KEY"));
  },
};

//Action
export const SecurityAction = {
  insertKey: (data, type) => {
    return {
      type,
      data: data,
    };
  },
};
