import React, { useRef, useState, useEffect } from "react";
import { useAuth } from "./../Context/AuthContext";
import { ImCross } from "react-icons/im";
import "../App.css";
import Loader2 from "./Loader2";
import Loader from "./Loader";
import { AuthService, userAuthstate } from "../Service/authenticationService";
import CustomButton from "../utils/customButton";
import useS3Uploader from "../hooks/useS3Uploader";

function AccountUpdate(props) {
  const name = useRef("");
  const bio = useRef("");
  const fileRef = useRef(null);
  // const [setImgLocation] = useState('');
  const { currentUser } = useAuth();
  const [loader] = useState(false);
  const [imgUploaded, setImgUpload] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    avatar: "",
    bio: "",
  });
  const [error, setError] = useState({
    avatar: "",
    name: "",
    bio: "",
  });

  const authState = userAuthstate();
  let update = {
    avatar: "",
    name: "",
    bio: "",
  };

  const { uploadFile, isLoading: isS3Loading } = useS3Uploader();

  const onChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: "" });
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImgUpload(true);
    } else {
      setImgUpload(false);
    }
  };

  useEffect(() => {
    if (currentUser.uid !== null) {
      authState.merge({ loading: true });
      AuthService.FetchAuthUser(currentUser.uid);
    }
  }, [authState.updateNeed.value]);

  const user = authState.authUser.value;
  useEffect(() => {
    if (user !== null) {
      setUserData({
        name: user.name !== undefined ? user.name : "",
        bio: user.bio !== undefined ? user.bio : "",
        avatar: user.avatar !== undefined ? user.avatar : "",
      });
    }
  }, []);

  useEffect(() => {
    if (authState.updatedUser.value) {
      props.handleComplete();
    }
  }, [authState.updatedUser.value]);

  async function handleSubmit() {
    const userdata = userData;
    const avatarInput = user.avatar ? user.avatar : fileRef.current.files[0];

    if (!avatarInput) {
      return setError({ ...error, avatar: "avatar is required" });
    }

    try {
      setError({ avatar: "", name: "", bio: "" });

      if (imgUploaded) {
        const file = fileRef.current.files[0];
        const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];

        if (!allowedFileTypes.includes(file.type)) {
          console.log("Please select a JPEG, JPG, or PNG file.");
          return; // Abort the upload if the selected file type is not allowed
        }

        console.log("img uopldd:-", imgUploaded);
        const data = await uploadFile(fileRef.current.files[0], true);

        update = {
          bio: bio.current.value,
          name: name.current.value,
          avatar: data.Location,
        };
      } else {
        update = {
          bio: bio.current.value,
          name: name.current.value,
          avatar: "",
        };
      }
      console.log("update:-", update);
      userdata.name = update.name !== "" ? update.name : userdata.name;
      userdata.bio = update.bio !== "" ? update.bio : userdata.bio;
      userdata.avatar = update.avatar !== "" ? update.avatar : userdata.avatar;

      console.log(userData);

      if (userdata.name === "")
        return setError({ ...error, name: "name is required" });
      if (userdata.bio === "")
        return setError({ ...error, bio: "bio is required" });
      AuthService.UpdateAuthUser(currentUser.uid, userdata);
    } catch (error) {
      setError("Failed to Update Profile");
    }
  }

  return (
    <>
      {authState.loading.value || isS3Loading ? (
        <Loader2 />
      ) : (
        <div>
          <>
            <div className="mb-3">
              <label className="text-darkBlack font-bold capitalize">
                Profile Picture
              </label>
              <p className="text-xs">
                We only accept file formats jpg, jpeg and png.
              </p>
              <br></br>
              {userData.avatar === "" && (
                <input
                  id="imageInput"
                  type="file"
                  accept="jpeg, jpg, png"
                  ref={fileRef}
                  className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
                  onChange={handleChange}
                />
              )}
              {userData.avatar !== "" && (
                <div className="w-32 relative mt-4">
                  <img src={userData.avatar} alt="" className="object-cover " />
                  <div
                    className="absolute -top-2 -right-2 cursor-pointer"
                    onClick={() => setUserData({ ...userData, avatar: "" })}
                  >
                    <ImCross className="text-red" />
                  </div>
                </div>
              )}
              {error && error.avatar && (
                <span className="text-sm text-Red">{error.avatar}</span>
              )}
            </div>
          </>
          <div className="mb-3">
            <label className="text-darkBlack font-bold capitalize">Name</label>
            <p className="text-xs">
              Please use the name that you want brands to see when shipping
              product.
            </p>
            <input
              className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
              type="text"
              name="name"
              value={userData.name}
              onChange={onChange}
              ref={name}
            />
            {error && error.name && (
              <span className="text-sm text-Red">{error.name}</span>
            )}
          </div>
          <div className="mb-3">
            <label className="text-darkBlack font-bold capitalize">Bio</label>
            <p className="text-xs">
              Tell brands who you are and why they should consider you for job
              opportunities, keep it brief!
            </p>
            <textarea
              className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
              value={userData.bio}
              name="bio"
              onChange={onChange}
              maxLength={300}
              ref={bio}
            />
            <div className="flex justify-end">
              <span className="text-sm text-darkGray">
                {userData.bio.length}/{300} characters
              </span>
            </div>
            {error && error.bio && (
              <span className="text-sm text-Red">{error.bio}</span>
            )}
          </div>
          <CustomButton
            className=" mt-2 mx-auto rounded text-white py-2 px-14 block"
            type="button"
            disabled={loader}
            onClick={() => handleSubmit()}
          >
            Create Profile
          </CustomButton>
        </div>
      )}
    </>
  );
}
export default AccountUpdate;
