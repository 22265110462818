import React, { useEffect, useState } from "react";
import AWS from "aws-sdk";
import { SecurityService } from "../Service/securityService";

export default function useTranscoder() {
  const [isLoading, setIsLoading] = useState(false);
  const [reactTranscoderClient, setReactTranscoderClient] = useState(null);

  useEffect(() => {
    const getSecretKey = async () => {
      setIsLoading(true);
      try {
        const accessKey = await SecurityService.getSecretKey({
          name: "REACT_APP_AWS_S3_ACCESS_KEY",
        });
        const secretKey = await SecurityService.getSecretKey({
          name: "REACT_APP_AWS_S3_SECRET_KEY",
        });

        const transcoderClient = new AWS.ElasticTranscoder({
          region: "us-east-1",
          accessKeyId: accessKey.value,
          secretAccessKey: secretKey.value,
        });

        setReactTranscoderClient(transcoderClient);
      } catch (error) {
        console.error("Failed to get AWS S3 keys: ", error);
      }
      setIsLoading(false);
    };

    getSecretKey();
  }, []);

  const createJob = async (file) => {
    if (!reactTranscoderClient) {
      throw new Error("S3 client is not initialized");
    }

    const fileKey = file
      .replace("https://crafted-video-assets.s3.amazonaws.com/", "")
      .replace(/\s/g, "_");

    const params = {
      Input: {
        Key: fileKey,
        FrameRate: "auto",
        Resolution: "auto",
        AspectRatio: "auto",
        Interlaced: "auto",
        Container: "mp4",
      },
      Outputs: [
        {
          Key: `${fileKey.replace(/\.mov$/i, ".mp4").replace(/\s/g, "_")}`,
          Rotate: "0",
          PresetId: "1351620000001-000001",
        },
      ],
      PipelineId: "1712849456709-w50a8j",
    };

    return new Promise((resolve, reject) => {
      reactTranscoderClient.createJob(params, function (err, data) {
        if (err) {
          console.log(err, err.stack); // an error occurred
          reject(err);
        } else {
          console.log(data); // successful response
          resolve(data);
        }
      });
    });
  };

  return {
    createJob,
    isLoading,
    setIsLoading,
  };
}
