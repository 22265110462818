import React, { useEffect, useState } from "react";
import AWS from "aws-sdk";
import { SecurityService } from "../Service/securityService";

export default function useS3Uploader() {
  const [isLoading, setIsLoading] = useState(false);
  const [reactS3Client, setReactS3Client] = useState(null);

  useEffect(() => {
    const getSecretKey = async () => {
      setIsLoading(true);
      try {
        const accessKey = await SecurityService.getSecretKey({
          name: "REACT_APP_AWS_S3_ACCESS_KEY",
        });
        const secretKey = await SecurityService.getSecretKey({
          name: "REACT_APP_AWS_S3_SECRET_KEY",
        });

        const s3Client = new AWS.S3({
          accessKeyId: accessKey.value,
          secretAccessKey: secretKey.value,
        });

        setReactS3Client(s3Client);
      } catch (error) {
        console.error("Failed to get AWS S3 keys: ", error);
      }
      setIsLoading(false);
    };

    getSecretKey();
  }, []);

  const uploadFile = async (file, uniquePrefix = false, key = "") => {
    if (!reactS3Client) {
      throw new Error("S3 client is not initialized");
    }

    const fileName = uniquePrefix
      ? `${Date.now()}-${key || file.name}`
      : key || file.name;

    const params = {
      Bucket: "crafted-video-assets",
      Key: fileName,
      Body: file,
      ContentType: file.type,
    };

    try {
      return reactS3Client.upload(params).promise();
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return {
    uploadFile,
    isLoading,
    setIsLoading,
  };
}
