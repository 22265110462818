import React, { useRef, useState } from "react";
import { validateFormFields } from "../utils/functions";
import GlobalDialog from "../utils/globalDialog";
import FileUpload from "./FileUpload";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { BsTrash } from "react-icons/bs";
import Loader from "./Loader";
import { useAuth } from "../Context/AuthContext";
import { useRecipeState, RecipeService } from "../Service/RecipeServices";
import CustomButton from "../utils/customButton";
import useS3Uploader from "../hooks/useS3Uploader";

const fieldErrors = {
  name: "Please Enter Name Carefully",
  image: "Please Select Image",
  description: "Please Enter  Description Carefully",
  tags: "tags are required",
  ingredients: "Please Enter  ingredients Carefully",
};

function AddRecipe(props) {
  const { open, onClose } = props;
  const { currentUser } = useAuth();
  const recipeState = useRecipeState();
  //for Input Handling
  const name = useRef("");
  const description = useRef("");
  const image = useRef("");
  const [errors, setErrors] = useState({});
  const [tags, setTags] = useState(["easy"]);
  const [recipeImage, setRecipeImage] = useState(null);
  const data = {
    name: "",
    uid: "",
    description: "",
    thumbnail: "",
    recipeSteps: [],
    recipeIngred: [],
    recipeMetatags: [],
  };
  const [stepsDesc, setStepsDesc] = useState([
    {
      step: "",
    },
  ]);
  const [ingredients, setIngredients] = useState([
    {
      name: "",
      measurement: "",
      unit: "",
    },
  ]);

  const { uploadFile, isLoading: isS3Loading } = useS3Uploader();

  //add ingredients
  const handleRemoveIng = (index) => {
    const listing = [...ingredients];
    listing.splice(index, 1);
    setIngredients(listing);
  };

  const handleInputChangeIng = (e, index) => {
    const { name, value } = e.target;
    const listIng = [...ingredients];
    listIng[index][name] = value;
    setIngredients(listIng);
  };

  //add steps functionality

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...stepsDesc];
    list[index][name] = value;
    setStepsDesc(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...stepsDesc];
    list.splice(index, 1);
    setStepsDesc(list);
  };

  //on Form Handle Submit
  const handleSubmit = async (event) => {
    event.preventDefault();

    setErrors({});
    const formValues = {
      name: name.current.value,
      description: description.current.value,
      image: image.current.value,
      tags,
    };

    const validationErrors = validateFormFields(formValues, fieldErrors);
    if (Object.keys(validationErrors).length) {
      return setErrors(validationErrors);
    }
    if (tags.length < 1) {
      return;
    }

    recipeState.merge({ loading: true });
    let url;
    if (image) {
      const response = await uploadFile(image.current.files[0], true);

      url = response.Location;
    }

    const temp = data;
    temp.name = name.current.value;
    temp.description = description.current.value;
    temp.thumbnail = url;
    temp.recipeSteps = stepsDesc;
    temp.recipeIngred = ingredients;
    temp.recipeMetatags = tags;
    temp.uid = currentUser.uid;
    if (currentUser.uid) RecipeService.AddRecipe(data);
    onClose();
  };

  return (
    <GlobalDialog open={open} onClose={onClose}>
      <h1 className="font-bold  text-2xl mt-2 ml-4 text-gray-600">
        Create Recipe
      </h1>
      <form onSubmit={handleSubmit} className="p-4">
        <div className="m-2">
          <label
            htmlFor="profileName"
            className="block text-sm font-medium text-gray-700"
          >
            Name
          </label>
          <input
            name="profileName"
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Enter Name"
            required
            ref={name}
          />
          <div className="text-red px-3 font-medium">{errors.name || ""}</div>
        </div>
        <div className="m-2">
          <label
            htmlFor="Description"
            className="block text-sm font-medium text-gray-700"
          >
            Description
          </label>
          <input
            name="profileName"
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Enter Description"
            required
            ref={description}
          />
          <div className="text-red px-3 font-medium">
            {errors.description || ""}
          </div>
        </div>
        <div className="m-2">
          <label
            htmlFor="tags"
            className="block text-sm font-medium text-gray-700"
          >
            Tags
          </label>

          <ReactTagInput
            tags={tags}
            maxTags={6}
            onChange={(newTags) => setTags(newTags)}
          />
          <div className="text-red px-3 font-medium">{errors.tags || ""}</div>
        </div>
        <div className="m-2">
          <FileUpload
            title="Recipe Image"
            image={recipeImage}
            imageRef={image}
            error={errors.image}
            onChange={(event) => {
              if (event.target.files && event.target.files[0]) {
                let reader = new FileReader();
                reader.onload = (e) => {
                  setRecipeImage(e.target.result);
                };
                reader.readAsDataURL(event.target.files[0]);
              }
            }}
          ></FileUpload>
        </div>
        <table className="w-full">
          {ingredients.map((ingredient, i) => {
            return (
              <tbody key={i}>
                {i === 0 && (
                  <tr>
                    <th>
                      <label
                        htmlFor="ingredientMeasurement"
                        className="block text-sm mx-2 text-left font-medium text-gray-700"
                      >
                        Ingredient Measurement
                      </label>
                    </th>
                    <th>
                      <label
                        htmlFor="ingredientUnit"
                        className="block text-sm mx-2 text-left font-medium text-gray-700"
                      >
                        Ingredient Unit
                      </label>
                    </th>
                    <th>
                      <label
                        htmlFor="ingredientName"
                        className="block text-sm mx-2 text-left font-medium text-gray-700"
                      >
                        Ingredient Name
                      </label>
                    </th>
                    <th />
                  </tr>
                )}
                <tr>
                  <td>
                    <div className="mx-2 mb-2">
                      <input
                        name="measurement"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Enter Ingredient Measurement"
                        value={ingredient.measurement}
                        required
                        onChange={(e) => {
                          handleInputChangeIng(e, i);
                        }}
                      />
                      <div className="text-red px-3 font-medium">
                        {errors.ingredientMeasurement || ""}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="mx-2 mb-2">
                      <select
                        name="unit"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Enter Ingredient Unit"
                        value={ingredient.unit}
                        onChange={(e) => {
                          handleInputChangeIng(e, i);
                        }}
                      >
                        <option></option>
                        <option>whole</option>
                        <option>ounces</option>
                        <option>lbs</option>
                        <option>cups</option>
                        <option>kg</option>
                        <option>g</option>
                        <option>tsp</option>
                        <option>tbsp</option>
                        <option>L</option>
                        <option>mL</option>
                      </select>
                      <div className="text-red px-3 font-medium">
                        {errors.ingredientUnit || ""}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="mb-2 mx-2">
                      <input
                        name="name"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Enter Ingredient Name"
                        value={ingredient.name}
                        required
                        onChange={(e) => {
                          handleInputChangeIng(e, i);
                        }}
                      />
                      <div className="text-red px-3 font-medium">
                        {errors.ingredientName || ""}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      className="mx-2 text-gray-400 cursor-pointer"
                      onClick={() => handleRemoveIng(i)}
                    >
                      <BsTrash className="inline text-center" />
                    </div>
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
        <div
          className="border-dashed border-2 text-center text-sm p-2 m-2 cursor-pointer"
          onClick={() => {
            setIngredients([
              ...ingredients,
              { name: "", measurement: "", unit: "" },
            ]);
          }}
        >
          Add more Ingredients
        </div>
        <div className="text-red px-3 font-medium">
          {errors.ingredients || ""}
        </div>
        <div className="mt-4">
          {stepsDesc.map((x, i) => {
            return (
              <>
                {i === 0 && (
                  <label
                    htmlFor="ingredientMeasurement"
                    className="block text-sm font-medium text-gray-700 mt-2"
                  >
                    Steps Description
                  </label>
                )}
                <div className="flex w-full items-center mt-2">
                  <div className="mr-1">Step</div>
                  <div className="mr-2">{i + 1}:</div>
                  <input
                    type="text"
                    name="step"
                    value={x.step}
                    required
                    placeholder="Enter Steps Description"
                    onChange={(e) => {
                      handleInputChange(e, i);
                    }}
                    className="bg-gray-100 py-2 px-4 w-full outline-none"
                  />
                  <div
                    className="text-gray-400 cursor-pointer place-items-center"
                    onClick={() => handleRemoveClick(i)}
                  >
                    <BsTrash className="inline text-center" />
                  </div>
                </div>
              </>
            );
          })}
          <div className="text-red px-3 font-medium">{errors.steps || ""}</div>
          <div
            className="border-dashed border-2 text-center text-sm p-2 mt-2 cursor-pointer"
            onClick={() => {
              setStepsDesc([...stepsDesc, { step: "" }]);
            }}
          >
            Add more Steps
          </div>
        </div>
        <div className="pt-3 mb-2 flex justify-center">
          <CustomButton
            type="submit"
            className="items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {recipeState.loading.value && <Loader />}
            Add Recipe
          </CustomButton>
        </div>
      </form>
    </GlobalDialog>
  );
}

export default AddRecipe;
