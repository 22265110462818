import React, { useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAuth } from "./../../Context/AuthContext";
import useS3Uploader from "../../hooks/useS3Uploader";
import "../../App.css";
import Loader from "./../Loader";
import { AuthService } from "../../Service/authenticationService";
import { BrandService } from "../../Service/BrandServices";
import { Mixpanel } from "../../mixpanel";
import CustomButton from "../../utils/customButton";

function OrgAccountCreation() {
  const name = useRef("");
  const bio = useRef("");
  const website = useRef("");
  const fileRef = useRef(null);
  const { id } = useParams();
  const history = useHistory();
  const { currentBrand } = useAuth();
  const [loader, setLoader] = useState(false);
  const [imgUploaded, setImgUpload] = useState(false);
  const [companyData, setCompanyData] = useState({
    name: "",
    avatar: "",
    website: "",
    bio: "",
  });
  const [error, setError] = useState({
    avatar: "",
    name: "",
    website: "",
    bio: "",
  });

  let update = {
    avatar: "",
    name: "",
    website: "",
    bio: "",
  };

  const { uploadFile, isLoading: isS3Loading } = useS3Uploader();

  const onChange = (e) => {
    setCompanyData({ ...companyData, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: "" });
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImgUpload(true);
    } else {
      setImgUpload(false);
    }
  };

  const handleSubmit = async () => {
    const companydata = companyData;
    const avatarInput = fileRef.current.files[0];
    if (!avatarInput) {
      return setError({ ...error, avatar: "avatar is required" });
    }
    if (name.current.value === "")
      return setError({ ...error, name: "name is required" });
    if (bio.current.value === "")
      return setError({ ...error, bio: "bio is required" });
    if (website.current.value === "")
      return setError({ ...error, website: "bio is required" });

    setLoader(true);
    try {
      setError({ avatar: "", name: "", bio: "" });
      if (imgUploaded) {
        console.log("img uopldd:-", imgUploaded);
        const data = await uploadFile(fileRef.current.files[0], true);
        console.log("data:-", data);
        update = {
          bio: bio.current.value,
          name: name.current.value,
          website: website.current.value,
          avatar: data.Location,
        };
      } else {
        update = {
          bio: bio.current.value,
          name: name.current.value,
          website: website.current.value,
          avatar: "",
        };
      }
      companydata.name = update.name;
      companydata.bio = update.bio;
      companydata.website = update.website;
      companydata.avatar = update.avatar;
      await AuthService.UpdateAuthAccount(currentBrand.uid, companydata);
      Mixpanel.completeRegistration(companydata);
      setLoader(false);
      history.push("/organization/dashboard/brands/" + id);
      window.location.reload();
    } catch (error) {
      setError("Failed to Update Profile");
    }
  }

  return (
    <>
      <div>
        <>
          <div className="mb-3">
            <label className="text-darkBlack font-bold capitalize">
              Profile picture
            </label>
            <br></br>
            <input
              id="imageInput"
              type="file"
              accept="jpeg, jpg, png"
              ref={fileRef}
              className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
              onChange={handleChange}
            />
            {error && error.avatar && (
              <span className="text-sm text-Red">{error.avatar}</span>
            )}
          </div>
        </>
        <div className="mb-3">
          <label className="text-darkBlack font-bold capitalize">
            Tell us a little about your company
          </label>
          <textarea
            className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
            rows="7"
            name="bio"
            onChange={onChange}
            ref={bio}
          />
          {error && error.bio && (
            <span className="text-sm text-Red">{error.bio}</span>
          )}
        </div>
        <CustomButton
          className=" mt-2 mx-auto text-white py-2 px-14 block"
          type="button"
          disabled={loader}
          onClick={() => handleSubmit()}
        >
          {loader && <Loader />}
          Join Us
        </CustomButton>
      </div>
    </>
  );
}
export default OrgAccountCreation;
