import React, { useState, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import { BsChevronCompactDown, BsChevronCompactUp } from "react-icons/bs";
import Collapsible from "react-collapsible";
import VideoCarousel from "./VideoCarousel";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { toast, Toaster } from "react-hot-toast";
import { useAuth } from "../../Context/AuthContext";
import { UsersService, useUserState } from "../../Service/userServices";
import Loader from "../Loader";
import Loader2 from "../Loader2";
import { FaRegCopy } from "react-icons/fa";
import { useRecipeState, RecipeService } from "../../Service/RecipeServices";
import {
  CampaignService,
  useCampaignState,
} from "../../Service/CampaignServices";
import { CampaignEmailService } from "../../Service/CampaignEmailService";
import CustomButton from "../../utils/customButton";
import useSubmitStatus from "../../hooks/useSubmitStatus";
import useFileUploader from "../../hooks/useFileUploader";
import FileProgressBar from "../FileProgressBar";
import useS3Uploader from "../../hooks/useS3Uploader";
import useTranscoder from "../../hooks/useTranscoder";

const bg = {
  overlay: {
    background: "rgba(128, 128, 128, 0.099)",
  },
};

const TaskDetails = (props) => {
  const { task } = props;
  const { currentUser } = useAuth();
  const [caption, setCaption] = useState(
    props.task.drafts[currentUser.uid].caption,
  );
  const draftId = currentUser.uid;
  const [copied, setCopied] = useState(false);
  const draft = task.drafts[draftId];
  const campaignState = useCampaignState();
  const [reviewLink, setReviewLink] = useState("");
  const [final, setFinal] = useState(false);

  const { retrySubmit, setRetrySubmit, isSubmitting, setIsSubmitting } =
    useSubmitStatus();

  const { uploadFile, isLoading: isS3Loading } = useS3Uploader();
  const { createJob, isLoading: isTranscoderLoading } = useTranscoder();

  const {
    files: files,
    inputRef: videoInputRef,
    progress: videoUploadProgress,
    uploadError: videoUploadError,
    uploadHanlder: uploadVideoHandler,
    handleRetryUpload: handleRetryVideoUpload,
  } = useFileUploader();

  const {
    files: photo,
    inputRef: photoInputRef,
    progress: photoUploadProgress,
    uploadError: photoUploadError,
    uploadHanlder: uploadPhotoHandler,
    handleRetryUpload: handleRetryPhotoUpload,
  } = useFileUploader();

  const {
    files: review,
    inputRef: reviewInputRef,
    progress: reviewUploadProgress,
    uploadError: reviewUploadError,
    uploadHanlder: uploadReviewHandler,
    handleRetryUpload: handleRetryReviewUpload,
  } = useFileUploader();
  let videoLinks = {};
  let s3PhotoLinks = {};
  let s3ReviewLink = {};

  const userState = useUserState();

  useEffect(() => {
    if (currentUser !== null) {
      UsersService.FetchUserInfo(currentUser.uid);
    }
  }, [userState.updateNeed.value]);
  const user = userState.user.value;
  //console.log(user.name)

  const recipeState = useRecipeState();
  useEffect(() => {
    if (recipeState.updateNeed.value) {
      RecipeService.FetchRecipeForUser(currentUser.uid);
    }
  }, [recipeState.updateNeed.value]);
  const res = recipeState.recipes.value;
  const recipe = res
    ? Object.entries(res).map((e) => ({
        [e[0]]: e[1],
      }))
    : [];
  const resubmitDeliverables = async (event) => {
    if (isSubmitting) {
      return; // Return early if the form is already submitting
    }
    setIsSubmitting(true);
    event.preventDefault();
    try {
      let dated = moment().format("MMM_DD_YYYY_HHmmss");
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        console.log("File #" + i);
        console.log(file);
        file = new Blob([file], { type: file.type });
        file.name = files[i].name.replace(/\s/g, "_");
        const userName =
          user.name ||
          (user && user.shipping_details && user.shipping_details.fullname) ||
          user.username;
        const brandName = props.task.brand_name; // Replace with your actual brand name
        const newName = `${userName}_${brandName}_${dated}${file.name.substr(
          file.name.lastIndexOf("."),
        )}`.replace(/ /g, "_");

        // Create a new File object with the new name
        file = new File([file], newName, { type: file.type });
        try {
          const data = await uploadFile(file, newName);
          console.log("File String #" + i);
          console.log(data.Location);

          if (file.name.toLowerCase().endsWith(".mov")) {
            // Transcode the file
            await createJob(data.Location);

            // Replace the .mov file with the .mp4 file in the videoLinks array
            videoLinks[`video${i + 1}`] = data.Location.replace(
              /\.mov$/i,
              ".mp4",
            ).replace(/\s/g, "_");
          } else {
            videoLinks[`video${i + 1}`] = data.Location.replace(/\s/g, "_");
          }
        } catch (error) {
          console.error(`Failed to upload file: ${file.name}`, error);
        }
      }
      const dt = new Date();
      let date = dt.toString();

      if (review.length > 0) {
        for (let i = 0; i < review.length; i++) {
          const file = review[i]; // Use `file` instead of `review_file`
          console.log("File #" + i);
          console.log(file);
          const data = await uploadFile(file);
          console.log("File String #" + i);
          console.log(data.Location);

          s3ReviewLink[`review${i + 1}`] = data.Location.toString();
        }
      }
      if (photo.length > 0) {
        for (let i = 0; i < photo.length; i++) {
          const file = photo[i];
          const data = await uploadFile(file);
          s3PhotoLinks[`photo${i + 1}`] = data.Location.toString();
        }

        // After uploading all files, check if the number of files exceeds three
        if (photo.length !== 3) {
          // Notify the user that only 3 files can be uploaded
          alert("You can only upload up to 3 files");
          setIsSubmitting(false);
          return; // Exit the function or handle the error accordingly
        }
      }

      console.log("Task id : " + task.id);
      let revision = {
        recipeIdsNames:
          props.task &&
          props.task.add_ons &&
          props.task.add_ons.some((Addon) => Addon.addon === "Recipes")
            ? recipe
            : {},
        photoLinks: s3PhotoLinks,
        videoLinks: videoLinks,
        task_id: task.id,
        creator_id: currentUser.uid,
        brand_id: task.brand_id,
        creator_name:
          props.task.brand_name +
          "_" +
          ((user && user.name) ||
            (user && user.shipping_details && user.shipping_details.fullname) ||
            (user && user.username)) +
          "_" +
          date.substring(4, 21),
        campaign_id: task.campaign_id,
        caption: caption,
      };
      console.log("Revision : " + revision);
      const emailData = {
        brand_id: props.task.brand_id,
        creator_id: currentUser.uid,
        task_id: props.task.id,
      };
      CampaignService.ResubmitDeliverables(task.id, revision);
      CampaignEmailService.SendRevisionEmail(emailData);
      CampaignEmailService.ReceiveRevisionEmail(emailData);
      toast.success("Deliverables Submitted Successfully", { duration: 3000 });
      props.fetchInfluencerTasks();
      setTimeout(() => {
        setRetrySubmit(false);
        props.onClose();
      }, 3000);
    } catch (error) {
      setRetrySubmit(true);
      toast.error(error.message || "Something went wrong", { duration: 3000 });
      // Handle errors, e.g., show an error message or log the error
    } finally {
      // Reset the submitting state if needed
      setIsSubmitting(false);
    }
  };

  const handleCopy = () => {
    const link = props.task.approved_proposals[currentUser.uid].short_link;
    navigator.clipboard
      .writeText(link)
      .then(() => setCopied(true))
      .catch((error) => console.error("Error copying link: ", error));
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        center
        styles={bg}
        classNames={{ modal: "customModal" }}
        closeOnOverlayClick={true}
      >
        {isS3Loading || isTranscoderLoading ? (
          <Loader2 />
        ) : (
          <>
            <Toaster />
            <Collapsible
              trigger={[
                "Brief Details",
                <div className="px-3 absolute right-0">
                  <BsChevronCompactDown />
                </div>,
              ]}
              triggerWhenOpen={[
                "Brief Details",
                <div className="px-3 absolute right-0">
                  <BsChevronCompactUp />
                </div>,
              ]}
              open={false}
              contentOuterClassName="mb-6"
              triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
              triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            >
              {/* Brand */}
              <div className="m-1 bg-white rounded-lg text-3xl dark:bg-gray-700 p-5">
                <div className="flex ml-12 mb-5">
                  <div className="flex mr-5 font-bold">Brand:</div>
                  <a
                    className="text-blue underline"
                    target="_blank"
                    href={`http://${props.task && props.task.brand_website}`}
                    rel="noreferrer"
                  >
                    {props.task && props.task.brand_name}
                  </a>
                </div>
                {/* Deadline */}
                {/*<div className="flex ml-12 mb-5">
                <div className="flex mr-5 font-bold">Deadline:</div>
                <div>{props.task && props.key_audience}</div>
              </div>*/}

                {/* Key Audience */}
                <div className="flex ml-12 mb-5">
                  <div className="flex mr-5 font-bold">Key Audience:</div>
                  <div>{props.task && props.task.target_audience}</div>
                </div>
                {/* Task Talking Points */}
                <div className="flex ml-12 mb-5">
                  <div className="flex mr-5 font-bold">Talking Points:</div>
                  <div>{props.task && props.task.talking_points}</div>
                </div>
              </div>
            </Collapsible>
            <Collapsible
              trigger={[
                "Examples of Video Types",
                <div className="px-3 absolute right-0">
                  <BsChevronCompactDown />
                </div>,
              ]}
              triggerWhenOpen={[
                "Examples of Video Types",
                <div className="px-3 absolute right-0">
                  <BsChevronCompactUp />
                </div>,
              ]}
              open={false}
              contentOuterClassName="mb-6"
              triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
              triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            >
              <div className="m-3">
                <VideoCarousel />
              </div>
            </Collapsible>

            <Collapsible
              trigger={[
                "Send Revised Video",
                <div className="px-3 absolute right-0">
                  <BsChevronCompactDown />
                </div>,
              ]}
              triggerWhenOpen={[
                "Send Revised Video",
                <div className="px-3 absolute right-0">
                  <BsChevronCompactUp />
                </div>,
              ]}
              open={true}
              contentOuterClassName="mb-6"
              triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
              triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            >
              <form onSubmit={resubmitDeliverables}>
                <div className="flex flex-col my-5">
                  {props.task && props.task.caption_instructions && (
                    <div>
                      <label htmlFor="caption">Video Caption:</label>
                      <textarea
                        value={caption}
                        onChange={(e) => setCaption(e.target.value)}
                        className="mt-4 mb-4 mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                        id="caption"
                        required
                        placeholder="Add the caption for your video."
                        name="caption"
                        rows="4"
                        cols="50"
                      ></textarea>
                    </div>
                  )}
                  {props.task && props.task.traffic_link && (
                    <div className="text-gray-500 my-2 font-thin">
                      <span className="font-bold text-gray-800 underline">
                        Reminder
                      </span>
                      : This is the link that is required to be put in your bio
                      for 30 days once you have successfully uploaded the
                      post(s).
                      <div className="flex items-center">
                        <a
                          href={
                            props.task.approved_proposals[currentUser.uid]
                              .short_link
                          }
                          className="font-bold text-blue underline"
                          target="_blank"
                        >
                          {
                            props.task.approved_proposals[currentUser.uid]
                              .short_link
                          }
                        </a>
                        <button onClick={handleCopy} className="ml-2">
                          <FaRegCopy title="Copy link" />
                        </button>
                        {copied && (
                          <span className="ml-2 text-green-500">
                            Link copied!
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="font-bold text mr-2">
                    <div>
                      <h3 className="mb-2">Current Video:</h3>
                      {draft && (
                        <video
                          controls
                          className="w-full min-w-1/3 h-21 rounded-lg object-contain"
                        >
                          <source src={draft.video} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </div>
                    <h3 className="mt-2 font-thin">
                      <span className="font-bold">Revision notes</span>:{" "}
                      {draft.revision_notes}
                    </h3>
                    {props.task &&
                      props.task.add_ons &&
                      props.task.add_ons.some(
                        (addon) => addon.name === "Recipes",
                      ) && (
                        <div>
                          <div className="mt-2 flex w-fit items-center relative group">
                            <p className="font-bold">Add Recipe:</p>
                            <AiOutlineQuestionCircle className="ml-1" />
                            <div className="absolute border font-thin border-gray-400 top-0 right-0 transform translate-x-full -translate-y-full w-56 h-fit bg-gray-200 text-gray-800 py-2 px-4 rounded opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
                              Recipes must be added in the "Recipes" page before
                              submitting a recipe.
                            </div>
                          </div>
                          {recipe && recipe.length > 0 ? (
                            <select
                              required
                              className="mt-4 mb-2 mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                            >
                              <option value="" className="text-gray-400">
                                Select a recipe
                              </option>
                              {recipe.map((item) => {
                                const res = item[Object.keys(item)[0]];
                                const id = Object.keys(item)[0];
                                return (
                                  <option key={id} value={id}>
                                    {res.name}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            <h1 className="font-thin my-3">
                              You currently have no recipes. Please add a recipe{" "}
                              <Link
                                to="/creator/dashboard/recipes"
                                className="underline text-red font-bold"
                              >
                                here
                              </Link>
                            </h1>
                          )}
                        </div>
                      )}

                    {props.task &&
                      props.task.add_ons &&
                      props.task.add_ons.some(
                        (addon) => addon.name === "Review",
                      ) && (
                        <div>
                          <h3 className="font-bold flex items-center gap-3">
                            Screenshot of Product Review:{" "}
                            <rFileProgressBar progress={reviewUploadProgress} />
                            {reviewUploadError ? (
                              <div className="flex gap-3 items-center">
                                <span className="text-xs text-danger">
                                  {reviewUploadError}
                                </span>
                                <span
                                  className="text-white px-2 mr-3 rounded-lg outline-none text-xs bg-Blue cursor-pointer"
                                  onClick={handleRetryReviewUpload}
                                >
                                  Retry
                                </span>
                              </div>
                            ) : null}
                          </h3>{" "}
                          <input
                            id="screenshotInput"
                            type="file"
                            required
                            accept="image/png, image/jpeg"
                            className="focus:outline-none w-full mb-2 bg-lightGray p-2 mt-2 mr-2 rounded"
                            onChange={(e) => uploadReviewHandler(e)}
                            ref={reviewInputRef}
                          />
                        </div>
                      )}

                    {props.task &&
                      props.task.add_ons &&
                      props.task.add_ons.some(
                        (addon) => addon.name === "Photos",
                      ) && (
                        <div>
                          <h3 className="flex items-center gap-3">
                            Add three Product Photos:{" "}
                            <rFileProgressBar progress={photoUploadProgress} />
                            {photoUploadError ? (
                              <div className="flex gap-3 items-center">
                                <span className="text-xs text-danger">
                                  {photoUploadError}
                                </span>
                                <span
                                  className="text-white px-2 mr-3 rounded-lg outline-none text-xs bg-Blue cursor-pointer"
                                  onClick={handleRetryPhotoUpload}
                                >
                                  Retry
                                </span>
                              </div>
                            ) : null}
                          </h3>
                          <input
                            id="imageInput"
                            type="file"
                            required
                            accept="image/png, image/jpeg, image/jpg"
                            className="focus:outline-none w-full mb-2 bg-lightGray p-2 mt-2 mr-2 rounded"
                            multiple
                            max="3"
                            onChange={(e) => uploadPhotoHandler(e)}
                            ref={photoInputRef}
                          />
                        </div>
                      )}
                    <h3 className="mt-2 flex items-center gap-3">
                      Add Video:{" "}
                      <FileProgressBar progress={videoUploadProgress} />
                      {videoUploadError ? (
                        <div className="flex gap-3 items-center">
                          <span className="text-xs text-danger">
                            {videoUploadError}
                          </span>
                          <span
                            className="text-white px-2 mr-3 rounded-lg outline-none text-xs bg-Blue cursor-pointer"
                            onClick={handleRetryVideoUpload}
                          >
                            Retry
                          </span>
                        </div>
                      ) : null}
                    </h3>
                    <input
                      id="videoInput"
                      type="file"
                      required
                      accept="video/mp4, video/mov, video/avi"
                      className="focus:outline-none w-full bg-lightGray p-2 mt-2 mr-2 rounded"
                      multiple
                      onChange={(e) => uploadVideoHandler(e)}
                      ref={videoInputRef}
                    />
                  </div>
                  <div className="my-2">
                    <label className="inline-flex items-center mr-4">
                      <input
                        type="checkbox"
                        className="form-checkbox h-5 w-5 text-gray-600"
                        checked={final}
                        required
                        onChange={() => setFinal(!final)}
                      />
                      <span className="ml-2 text-base xs:text-0.7 w-full text-gray-700">
                        {" "}
                        I am submitting a video file that can be used as a final
                        deliverable - not a screen recording of the video from
                        editing app or a video watermarked with #draft, etc.{" "}
                      </span>
                    </label>
                  </div>
                  <CustomButton
                    type="submit"
                    className=" mx-auto text-white w-1/3 mt-8 py-4 outline-none text-base"
                    disabled={isSubmitting} // Disable the button when submitting
                  >
                    {isSubmitting ? (
                      <div className="flex items-center justify-center">
                        <Loader />
                        <h1 className="text-white ml-2">
                          {retrySubmit ? "Retrying" : "Sending"}...
                        </h1>
                      </div>
                    ) : retrySubmit ? (
                      "Retry Send Update"
                    ) : (
                      "Send Update"
                    )}
                  </CustomButton>
                </div>
              </form>
            </Collapsible>
          </>
        )}
      </Modal>
    </div>
  );
};

export default TaskDetails;
