import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { BsArrowLeft } from "react-icons/bs";
import {
  SecurityService,
  useSecurityState,
} from "../../Service/securityService";

function InstagramOnboardingInfoModal(props) {
  const { secretKeys } = useSecurityState().get();

  useEffect(() => {
    // Simulating an API call or any asynchronous task
    const getSecretKey = async () => {
      try {
        await SecurityService.getSecretKey({
          name: "REACT_APP_FACEBOOK_APP_CLIENT_ID",
        });
      } catch (error) {
        console.error("Failed to get TikTok's client key.", error);
      }
    };

    getSecretKey();
  }, []);

  const startInstagramLoginFlow = () => {
    const clientId = secretKeys["REACT_APP_FACEBOOK_APP_CLIENT_ID"];
    const redirectUri =
      "https://www.app.cookwithcrafted.com/creator/dashboard/instagram-success";
    const scope =
      "instagram_basic,instagram_manage_insights,pages_show_list,pages_read_engagement,business_management";
    const display = "page";
    const extras = { setup: { channel: "IG_API_ONBOARDING" } };
    const responseType = "token";
    // eslint-disable-next-line max-len
    const authUrl = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${clientId}&display=${display}&extras=${extras}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}`;

    window.location.href = authUrl;
  };

  return (
    <div className="bg-white rounded-md h-fit font-bold flex flex-col mt-6 md:mt-24 p-4 md:pl-8">
      <Toaster />
      <div className="items-start">
        <button
          className="flex flex-row mb-2 right-4 top-0 font-bold text-xs md:text-base"
          onClick={props.onClose}
        >
          <BsArrowLeft className="mx-2 mt-0.5 text-xs md:text-base" />
          Back
        </button>
        <h3 className="text-lg md:text-xl lg:text-3xl font-extrabold mx-auto text-center mb-4">
          Connect your Instagram account
        </h3>
        <p className="font-thin italic text-xs md:text-sm lg:text-lg xl:text-xl text-gray-600 tracking-tighter">
          In order to be eligible for influencer tasks that require an Instagram
          Account, you must first connect your Instagram Account to Crafted.
        </p>
        <div className="flex flex-col mt-4">
          <p className="my-5 text-xs md:text-base">
            Before connecting your account, please ensure you have the following
            requirements:
          </p>
          <ul className="list-disc flex flex-col ml-2 md:ml-5 text-xs md:text-base">
            <li>
              You must have a Facebook Page for your creator account (not a
              personal profile).
            </li>
            <li>
              You must have an Instagram Business Account or Instagram Creator
              Account.
            </li>
            <li>
              You must connect your Facebook Page (for your creator account) to
              your Instagram Business/Creator Account.
            </li>
          </ul>
          <p className="my-5 text-xs md:text-base">
            If you do not have these requirements, please visit the links below
            for instructions:
          </p>
          <div className="space-y-5 text-left text-xs md:text-base">
            <p>
              <a
                href="https://help.instagram.com/2358103564437429"
                target="_blank"
                rel="noreferrer"
                className="text-blue underline hover:underline"
              >
                How to set up a creator account on Instagram
              </a>
            </p>
            <p>
              <a
                href="https://www.facebook.com/business/help/473994396650734?id=939256796236247"
                target="_blank"
                rel="noreferrer"
                className="text-blue underline hover:underline"
              >
                Create a Facebook Page for your business (creator page)
              </a>
            </p>
            <p>
              <a
                href="https://help.instagram.com/570895513091465"
                target="_blank"
                rel="noreferrer"
                className="text-blue underline hover:underline"
              >
                Add or change the Facebook Page connected to your Instagram
                professional account
              </a>
            </p>
          </div>
        </div>
        <button
          // eslint-disable-next-line max-len
          className="mx-auto justify-center font-bold flex flex-row bg-gradient-to-t from-purple to-Orange mt-10 text-white rounded-md py-2 md:py-4 px-5 md:px-10 text-sm md:text-base"
          type="submit"
          onClick={() => startInstagramLoginFlow()}
        >
          <div className="instagram w-5 h-5 mr-3 mt-0.5"></div>Connect to
          Instagram
        </button>
      </div>
    </div>
  );
}

export default InstagramOnboardingInfoModal;
