import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  FaArrowDown,
  FaArrowUp,
  FaSearch,
  FaYoutube,
  FaInstagram,
  FaTiktok,
} from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Loader from "../Loader2";
import "./CreatorDiscoveryTable.css";
import Select, { components } from "react-select";
import { Toaster, toast } from "react-hot-toast";
import { baseUrl } from "../../baseUrl";
import { Parser } from "json2csv";
import { saveAs } from "file-saver";
import CreatorDiscoveryProfile from "./CreatorDiscoveryProfile";
import {
  SecurityService,
  useSecurityState,
} from "../../Service/securityService";

function CreatorDiscoverySearch() {
  const [sortOrder, setSortOrder] = useState({ field: "", order: "" });
  const [clickCount, setClickCount] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState("Instagram");
  const [selectedCode, setSelectedCode] = useState(
    "9bb8913b-ddd9-430b-a66a-d74d846e6c66",
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filterDropDown, setFilterDropDown] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedCreatorCities, setSelectedCreatorCities] = useState([]);
  const [selectedCreatorState, setSelectedCreatorState] = useState("");
  const [selectedAudienceLocationId, setSelectedAudienceLocationId] =
    useState("");
  const [selectedCreatorLocationId, setSelectedCreatorLocationId] =
    useState("");
  const [isClosing, setIsClosing] = useState(false);
  const [emailList, setEmailList] = useState([]);
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [offset, setOffset] = useState(0);
  const [creatorData, setCreatorData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [originalData, setOriginalData] = useState();
  const [searchMode, setSearchMode] = useState("profile");
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const [states, setStates] = useState([]);
  const [averageLikes, setAverageLikes] = useState([0, 0]);
  const [averageViews, setAverageViews] = useState([0, 0]);
  const [isLoading, setIsLoading] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  // FILTER STATES
  const [followerCount, setFollowerCount] = useState([0, 0]);
  const [creatorLocation, setCreatorLocation] = useState("");
  const [keyword, setKeyword] = useState("");
  const [contactOptions, setContactOptions] = useState("");
  const [email, setEmail] = useState("");
  const [creatorGender, setCreatorGender] = useState("");
  const [creatorAge, setCreatorAge] = useState([0, 0]);
  const [isVerified, setIsVerified] = useState(false);
  const [mostRecentPost, setMostRecentPost] = useState("");
  const [bioPhrase, setBioPhrase] = useState("");
  const [audienceLocation, setAudienceLocation] = useState("");
  const [audienceAge, setAudienceAge] = useState([0, 0]);

  const { secretKeys } = useSecurityState().get();

  useEffect(() => {
    // Simulating an API call or any asynchronous task
    const getSecretKey = async () => {
      try {
        await SecurityService.getPhylloToken();
      } catch (error) {
        console.error("Failed to get TikTok's client key: ", error);
      }
    };

    getSecretKey();
  }, []);

  const followerOptions = [
    1000, 5000, 10000, 25000, 50000, 100000, 250000, 500000, 1000000,
  ];
  const ageOptions = [13, 18, 25, 35, 45, 65];
  // CACHING THE TAGS
  const cache = new Map();

  const downloadCSV = () => {
    const fields = [
      "full_name",
      "follower_count",
      "is_verified",
      "engagement_rate",
      "url",
    ];
    const parser = new Parser({ fields });
    const csv = parser.parse(creatorData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "Discovery-Search.csv");
  };

  useEffect(() => {
    if (isClosing) {
      setTimeout(() => {
        setShowFilters(false);
        setIsClosing(false);
      }, 500); // Delay equal to animation duration
    }
  }, [isClosing]);

  useEffect(() => {
    const getStates = async () => {
      try {
        const response = await fetch(
          `${baseUrl}api/maintenance/getDiscoveryStates`,
        );
        if (!response.ok) {
          throw new Error("HTTP error " + response.status);
        }
        const data = await response.json();
        console.log("States:", data);
        setStates(data);
      } catch (error) {
        console.error("Failed to fetch states:", error);
      }
    };

    getStates();
  }, []);

  //ICONS FOR PLATFORMS
  const icons = {
    YouTube: {
      icon: (
        <div className="youtube 2xl:w-6 2xl:h-6 w-4 h-4 ml-2 items-center"></div>
      ),
      code: "14d9ddf5-51c6-415e-bde6-f8ed36ad7054",
    },
    Instagram: {
      icon: (
        <div className="instagram 2xl:w-6 2xl:h-6 w-4 h-4 ml-2 items-center"></div>
      ),
      code: "9bb8913b-ddd9-430b-a66a-d74d846e6c66",
    },
    TikTok: {
      icon: (
        <div className="tiktok 2xl:w-6 2xl:h-6 w-4 h-4 ml-2 items-center"></div>
      ),
      code: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
    },
  };
  //SELECT ALL CREATORS IN PAGE
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      // If the checkbox is checked, add all rows with an email contact detail to the selected rows and email list
      const newSelectedRows = [];
      const newEmailList = [];
      sortedData.forEach((row) => {
        if (row.contact_details) {
          const emailContact = row.contact_details.find(
            (contact) => contact.type === "email",
          );
          if (emailContact) {
            newSelectedRows.push(row.id);
            newEmailList.push(emailContact.value);
          }
        }
      });
      setSelectedRows(newSelectedRows);
      setEmailList(newEmailList);
      setSelectAll(true); // Add this line
    } else {
      // If the checkbox is unchecked, remove all rows from the selected rows and email list
      setSelectedRows([]);
      setEmailList([]);
      setSelectAll(false); // Add this line
    }
  };

  const handleSort = (field) => {
    let order = "asc";
    let count = clickCount[field] || 0;
    count++;

    if (count === 3) {
      count = 0;
      order = null;
    } else if (count === 2) {
      order = "desc";
    }

    setClickCount({ ...clickCount, [field]: count });
    setSortOrder({ field, order });

    if (order === null) {
      setSortedData(creatorData);
    } else {
      const sorted = [...creatorData].sort((a, b) => {
        // Use the original numerical values for sorting
        const aValue = a[field] || 0;
        const bValue = b[field] || 0;

        if (order === "asc") {
          return aValue - bValue;
        } else if (order === "desc") {
          return bValue - aValue;
        }
      });

      setSortedData(sorted);
    }
  };

  const handleSendEmails = async () => {
    const requestBody = {
      email_list: emailList,
      subject: emailSubject,
      body: emailBody,
    };

    try {
      const response = await fetch(`${baseUrl}api/email/sendMassEmail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data.message);

      // Show a toast notification
      toast.success("Emails were sent successfully!", {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      console.error("Failed to send email:", error);

      // Show a toast notification for the error
      toast.error("Failed to send emails!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    setShowModal(false);
  };

  useEffect(() => {
    console.log(selectedTags);
  }, [selectedTags]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const term = searchTerm.trim();

    if (term !== "" && !selectedTags.includes(term)) {
      const newTags = selectedTags.slice(); // This creates a copy of selectedTags
      newTags.push(term); // This adds the new term to the copied array
      setSelectedTags(newTags); // This sets the state to the new array
      setSearchTerm("");
    }
  };

  const handleRemoveTag = (event, tagToRemove) => {
    event.stopPropagation(); // This prevents the event from propagating up to the form's onSubmit event
    setSelectedTags(selectedTags.filter((tag) => tag !== tagToRemove));
  };

  const handleRowSelect = (event, id) => {
    const emailContact = sortedData
      .find((row) => row.id === id)
      .contact_details.find((contact) => contact.type === "email");
    if (emailContact) {
      const email = emailContact.value;
      if (selectedRows.includes(id)) {
        setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
        setEmailList(emailList.filter((e) => e !== email));
        if (selectAll) setSelectAll(false); // Add this line
      } else {
        setSelectedRows([...selectedRows, id]);
        setEmailList([...emailList, email]);
        if (!selectAll && selectedRows.length + 1 === sortedData.length)
          setSelectAll(true); // Add this line
      }
    }
  };
  const handleStateChange = (selectedOption) => {
    const selectedState = selectedOption ? selectedOption.value : "";
    setSelectedState(selectedState);
    if (!states[selectedState]) {
      states[selectedState] = {}; // Add a new state to the states object
    }
    setSelectedCities(Object.values(states[selectedState]));
    setSelectedAudienceLocationId("");
  };

  const handleCityChange = (selectedOptions) => {
    const selectedCityIds = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setSelectedCities(selectedCityIds);
  };

  const handleCreatorStateChange = (selectedOption) => {
    const selectedState = selectedOption ? selectedOption.value : "";
    setSelectedCreatorState(selectedState);
    if (!states[selectedState]) {
      states[selectedState] = {}; // Add a new state to the states object
    }
    setSelectedCreatorCities(Object.values(states[selectedState]));
    setSelectedCreatorLocationId("");
  };

  const handleCreatorCityChange = (selectedOptions) => {
    const selectedCityIds = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setSelectedCreatorCities(selectedCityIds);
  };

  const history = useHistory();

  const handleRowClick = (row) => {
    setSelectedRow(row);
    setShowProfile(true);
  };

  useEffect(() => {
    console.log("Selected location id", selectedAudienceLocationId);
    console.log("Selected city", selectedCities);
  }, [selectedAudienceLocationId, selectedCities]);
  useEffect(() => {
    console.log("Selected location id", selectedCreatorLocationId);
    console.log("Selected city", selectedCreatorCities);
  }, [selectedCreatorLocationId, selectedCreatorCities]);
  const setters = {
    "Follower Count": setFollowerCount,
    "Creator Location": setCreatorLocation,
    Keyword: setKeyword,
    "Contact options": setContactOptions,
    Email: setEmail,
    "Creator Gender": setCreatorGender,
    "Creator Age": setCreatorAge,
    "is verified": setIsVerified,
    "Most Recent Post": setMostRecentPost,
    "Bio Phrase": setBioPhrase,
    "Audience Location": setAudienceLocation,
    "Audience Age": setAudienceAge,
  };

  function formatNumber(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + "M";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K";
    } else {
      return num;
    }
  }
  const data = [
    {
      id: 1,
      creator: "Creator 1",
      handle: "@creator1",
      followers: 1000,
      averageLikes: 500,
      engagementRate: "50%",
    },
    {
      id: 2,
      creator: "Creator 2",
      handle: "@creator2",
      followers: 2000,
      averageLikes: 1000,
      engagementRate: "50%",
    },
    {
      id: 3,
      creator: "Creator 3",
      handle: "@creator3",
      followers: 3000,
      averageLikes: 1500,
      engagementRate: "50%",
    },
    {
      id: 4,
      creator: "Creator 4",
      handle: "@creator4",
      followers: 4000,
      averageLikes: 2000,
      engagementRate: "50%",
    },
    {
      id: 5,
      creator: "Creator 5",
      handle: "@creator5",
      followers: 5000,
      averageLikes: 2500,
      engagementRate: "50%",
    },
    // Add more data as needed
  ];

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          {props.label}
        </components.Option>
      </div>
    );
  };

  const fetchEmails = async () => {
    const token = secretKeys["REACT_APP_PHYLLO_TOKEN"];

    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://api.insightiq.ai/v1/social/creators/profiles/quick-search",
        {
          work_platform_id: selectedCode,
          platform_username: {
            value: searchTerm,
            operator: "PREFIX",
          },

          sort_by: {
            field: "AVERAGE_LIKES",
            order: "DESCENDING",
          },
          limit: 100,
        },
        {
          headers: {
            Authorization: `Basic ${token}`,
          },
        },
      );
      //console.log("Response", response.data.data)
      const emails = response.data.data.flatMap((item) =>
        item.contact_details
          .filter((detail) => detail.type === "email")
          .map((detail) => detail.value),
      );

      // Update the email list state
      setEmailList(emails);
      console.log("Emails", emails);
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setIsLoading(false); // set loading back to false in the finally block
    }
  };
  const fetchData = async () => {
    setLoading(true);
    const token = secretKeys["REACT_APP_PHYLLO_TOKEN"];

    try {
      const response = await axios.post(
        "https://api.insightiq.ai/v1/social/creators/profiles/quick-search",
        {
          work_platform_id: selectedCode,
          platform_username: {
            value: searchTerm,
            operator: "PREFIX",
          },

          sort_by: {
            field: "AVERAGE_LIKES",
            order: "DESCENDING",
          },
          limit: 100,
        },
        {
          headers: {
            Authorization: `Basic ${token}`,
          },
        },
      );
      console.log("Response", response.data.data);
      setCreatorData(response.data.data);
      setOriginalData(response.data.data);
      setSortedData(response.data.data);
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setLoading(false); // set loading back to false in the finally block
    }
  };

  useEffect(() => {
    if (selectedIcon && selectedCode) {
      console.log("Selected icon", selectedIcon, "Selected code", selectedCode);
      fetchData();
    }
  }, [selectedIcon, selectedCode]);

  return (
    <div>
      <Toaster />
      {/**SEARCH BAR, TOGGLE FOR PLATFORM, AND TOGGLER FOR SEARCH METHOD */}
      <div className="p-3 md:p-2 sm:p-1 my-2 flex flex-row items-center justify-center ">
        <div className="mx-3" style={{ position: "relative", zIndex: 10 }}>
          <button
            className="bg-white shadow-lg rounded-md border-2 hover:bg-gray-100 text-white focus:outline-none 2xl:px-4 2xl:py-3 md:px-4 md:py-2 sm:px-5 sm:py-2 flex items-center justify-center transform transition-transform duration-500 ease-in-out space-x-2"
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            {selectedIcon && icons[selectedIcon].icon}
            {dropdownOpen ? (
              <IoIosArrowUp className="text-gray-600" />
            ) : (
              <IoIosArrowDown className="text-gray-600" />
            )}
          </button>
          {dropdownOpen && (
            <div
              className="absolute bg-white left-0 mt-2 w-48 rounded-md shadow-lg"
              style={{ zIndex: 50 }}
            >
              <div className="rounded-md ring-1 ring-black ring-opacity-5">
                {Object.keys(icons).map((icon) => (
                  <div
                    key={icon}
                    className="flex items-center px-4 py-2 font-bold text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => {
                      setSelectedIcon(icon);
                      setSelectedCode(icons[icon].code);
                      setDropdownOpen(false);
                    }}
                  >
                    {icons[icon].icon} <span className="ml-2">{icon}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <form onSubmit={fetchData} className="flex flex-col w-1/2 relative">
          <div className="bg-white flex items-center shadow-md border-2 py-2 text-gray-700 leading-tight focus:border-red rounded-md">
            <div className="flex flex-row flex-nowrap overflow-x-auto">
              {selectedTags.map((tag) => (
                <div
                  key={tag}
                  className="flex items-center m-1 h-6 bg-gray-200 rounded-full"
                >
                  <button
                    type="button"
                    onClick={(event) => handleRemoveTag(event, tag)}
                    className="p-1 ml-2 font-bold rounded-full"
                  >
                    x
                  </button>
                  <span className="px-2 py-1 text-sm">{tag}</span>
                </div>
              ))}
            </div>
            <input
              className="rounded-md flex-grow mx-auto 2xl:text-base md:text-0.7 p-5 md:p-2 sm:p-2 text-gray-700 leading-tight border-none focus:ring-0 focus:ring-offset-0 focus:outline-none 2xl:h-8 md:h-1rem"
              id="search"
              type="text"
              placeholder="Search for a person's username"
              onChange={handleInputChange}
              value={searchTerm}
            />
          </div>
        </form>
        <div className="shadow-lg ml-2">
          <button
            onClick={fetchData}
            className="bg-Blue shadow-lg text-base text-white rounded-md focus:outline-none 2xl:px-4 2xl:py-3 md:px-4 md:py-2 sm:px-5 sm:py-2 flex items-center justify-center transform transition-transform duration-500 ease-in-out hover:scale-105 space-x-2"
          >
            <FaSearch />
            <span>Search</span>
          </button>
        </div>
      </div>
      {/**END SEARCH BAR */}
      <div className="flex justify-between">
        {creatorData && (
          <div>
            <button
              className="bg-Blue text-white ml-3 font-bold 2xl:py-2 2xl:px-4 md:py-1 md:px-2  2xl:text-base md:text-0.6 rounded mb-2  transform transition-transform duration-500 ease-in-out hover:scale-105"
              onClick={downloadCSV}
            >
              Export CSV
            </button>
          </div>
        )}
        <div>
          {isLoading ? (
            <p className="font-nunito mr-5 text-base md:text-0.7 font-bold">
              Fetching emails. This may take a while...
            </p>
          ) : (
            emailList !== null &&
            emailList !== undefined &&
            creatorData && (
              <>
                {emailList.length > 0 ? (
                  <button
                    className="bg-Blue hover:bg-blue-700 mr-10 mb-2 text-white font-bold 2xl:py-2 2xl:px-4 md:py-1 md:px-2 2xl:text-base md:text-0.6  rounded transform transition-transform duration-500 ease-in-out hover:scale-105"
                    onClick={() => setShowModal(true)}
                  >
                    Send {emailList.length} Emails
                  </button>
                ) : (
                  <button
                    className="bg-Blue hover:bg-blue-700  mr-4 mb-2 text-white font-bold 2xl:py-2 2xl:px-4 md:py-1 md:px-2 2xl:text-base md:text-0.6 rounded transform transition-transform duration-500 ease-in-out hover:scale-105"
                    onClick={fetchEmails}
                  >
                    Fetch Emails
                  </button>
                )}

                {showModal && (
                  <div
                    className="fixed z-10 inset-0  overflow-y-auto"
                    aria-labelledby="modal-title"
                    role="dialog"
                    aria-modal="true"
                  >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                      <div
                        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                        aria-hidden="true"
                      ></div>

                      <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                      >
                        &#8203;
                      </span>

                      <div className="inline-block align-bottom w-full h-full bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                          <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                              <h3
                                className="text-lg leading-6 font-medium text-gray-900"
                                id="modal-title"
                              >
                                Send {emailList.length} recipients an email
                              </h3>
                              <div className="mt-2">
                                <label
                                  htmlFor="subject"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Subject
                                </label>
                                <input
                                  type="text"
                                  id="subject"
                                  className="mt-1 block w-28rem rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                  value={emailSubject}
                                  onChange={(e) =>
                                    setEmailSubject(e.target.value)
                                  }
                                />

                                <label
                                  htmlFor="body"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Body
                                </label>
                                <textarea
                                  id="body"
                                  className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                  value={emailBody}
                                  onChange={(e) => setEmailBody(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                          <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-Blue   text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={handleSendEmails}
                          >
                            Send
                          </button>
                          <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={() => setShowModal(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )
          )}
        </div>
      </div>

      {/**TABLE */}
      {loading ? (
        <div className="flex mx-auto items-center flex-col justify-center">
          <Loader />
          <h1 className="mt-10">Loading Results...</h1>
        </div>
      ) : (
        <>
          <div className="overflow-auto" style={{ maxHeight: "50px" }}>
            <table className="w-full divide-y divide-gray-200">
              <thead className="bg-gray-200">
                <tr className="grid grid-cols-4 font-bold">
                  {[
                    "Creator",
                    "Followers",
                    "Average Likes",
                    "Engagement Rate",
                  ].map((field, index) => (
                    <th
                      scope="col"
                      className={`px-6 py-2 text-left 2xl:text-base font-bold md:text-xs border-gray-200 hover:bg-gray-300 font-medium text-gray-500 uppercase tracking-wider transition-colors duration-500 ease-in-out hover:text-red-500 cursor-pointer select-none`}
                      onClick={() => handleSort(field)}
                    >
                      <div className="flex items-center justify-start">
                        {index === 0 && (
                          <input
                            type="checkbox"
                            className="w-4 h-4 mr-2"
                            checked={selectAll}
                            onChange={handleSelectAll}
                            onClick={(event) => event.stopPropagation()}
                          />
                        )}
                        <span className={index === 0 ? "ml-5" : ""}>
                          {field}
                        </span>
                        {sortOrder.field === field &&
                          sortOrder.order &&
                          (sortOrder.order === "asc" ? (
                            <FaArrowUp
                              className="ml-3"
                              style={{ display: "inline-block" }}
                            />
                          ) : (
                            <FaArrowDown
                              className="ml-3"
                              style={{ display: "inline-block" }}
                            />
                          ))}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
            </table>
          </div>
          <div className="overflow-auto" style={{ maxHeight: "64vh" }}>
            <table className="w-full divide-y divide-gray-200">
              <tbody className="bg-white divide-y divide-gray-200">
                {sortedData &&
                  sortedData.map((row) => {
                    return (
                      <tr
                        key={row.id}
                        className="grid grid-cols-4 font hover:bg-gray-100 transition-colors duration-500 ease-in-out cursor-pointer"
                        onClick={() => handleRowClick(row)}
                      >
                        <td className="px-6 py-2 text-left flex items-center 2xl:text-base md:text-0.7 whitespace-nowrap text-sm border-b text-gray-800">
                          <input
                            type="checkbox"
                            className="w-4 h-4 mr-2"
                            checked={selectedRows.includes(row.id)}
                            onChange={(event) => {
                              event.stopPropagation();
                              handleRowSelect(event, row.id);
                            }}
                            disabled={
                              !row.contact_details ||
                              !row.contact_details.some(
                                (contact) => contact.type === "email",
                              )
                            }
                          />
                          <img
                            src={row.image_url}
                            alt={row.full_name}
                            className="h-3rem w-3rem bg-red border-2 rounded-full ml-5"
                          />
                          <div className="ml-5 flex flex-col">
                            <span className="font-bold w-5rem overflow-auto truncate">
                              {row.full_name}
                            </span>
                            <span className="text-gray-500 text-xs hover:underline">
                              @
                              <a
                                href={row.url}
                                target="_blank"
                                className="hover:underline"
                                onClick={(event) => event.stopPropagation()}
                              >
                                {row.platform_username}
                              </a>
                            </span>
                          </div>
                        </td>
                        <td className="px-6 ml-5 py-2 text-left flex items-center 2xl:text-base md:text-xs whitespace-nowrap font-roboto text-sm border-b text-gray-800">
                          {formatNumber(
                            row.follower_count || row.subscriber_count,
                          )}
                        </td>
                        <td className="px-6 py-2 ml-5 text-left flex items-center 2xl:text-base md:text-xs whitespace-nowrap font-roboto text-sm border-b text-gray-800">
                          {formatNumber(row.average_likes)}
                        </td>
                        <td className="px-6 py-2 ml-5 text-left flex items-center 2xl:text-base md:text-xs whitespace-nowrap font-roboto text-sm border-b text-gray-800">
                          {Number(
                            (Number(row.engagement_rate) * 100).toFixed(2),
                          )}
                          %
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </>
      )}
      {/**END TABLE */}

      {showFilters && (
        <div className="h-screen">
          <div className="fixed h-screen inset-0 z-50 bg-black opacity-70 backdrop-blur-md"></div>
          <div
            className={`fixed right-0 top-0 bottom-0 w-2/3 z-50 bg-white overflow-auto ${
              isClosing ? "slide-out" : "slide-in"
            }`}
          >
            <div className="flex justify-between items-center m-4">
              <h1 className="font-bold font-roboto text-2xl">All Filters</h1>
              <button
                className="text-gray-500 font-bold"
                onClick={() => setIsClosing(true)}
              >
                Close
              </button>
            </div>
            <ul className="p-4 grid grid-cols-2 gap-4">
              {Object.keys(setters).map((filter) => (
                <div className="relative">
                  <li
                    className="relative flex items-center py-4 border shadow-md border-gray-300 rounded cursor-pointer hover:bg-gray-100 transition-colors duration-200 ease-in-out"
                    onClick={() => setFilterDropDown(filter)}
                  >
                    <span className="font-bold font-nunito text-2xl mx-2">
                      +
                    </span>
                    <span className="font-bold font-nunito">{filter}</span>
                  </li>
                  {filterDropDown === filter && (
                    <div
                      className="absolute left-0 bg-white w-full rounded-md shadow-lg"
                      style={{ zIndex: 50 }}
                    >
                      <div className="rounded-md border-2">
                        <div className="flex items-center px-4 py-2 font-bold text-sm text-gray-700 hover:bg-gray-100">
                          {[
                            "Follower Count",
                            "Audience Age",
                            "Creator Age",
                          ].includes(filter) ? (
                            <>
                              <select
                                onChange={(e) =>
                                  setters[filter][0](parseInt(e.target.value))
                                }
                              >
                                {filter === "Follower Count"
                                  ? followerOptions.map((option) => (
                                      <option value={option}>
                                        {option / 100}k
                                      </option>
                                    ))
                                  : ageOptions.map((option) => (
                                      <option value={option}>{option}</option>
                                    ))}
                              </select>

                              <select
                                onChange={(e) =>
                                  setters[filter][1](parseInt(e.target.value))
                                }
                              >
                                {filter === "Follower Count"
                                  ? followerOptions.map((option) => (
                                      <option value={option}>
                                        {option / 100}k
                                      </option>
                                    ))
                                  : ageOptions.map((option) => (
                                      <option value={option}>{option}</option>
                                    ))}
                              </select>
                            </>
                          ) : (
                            <input
                              type="text"
                              placeholder={`Set ${filter}`}
                              className="w-full  border-none focus:ring-0 focus:ring-offset-0 focus:outline-none"
                              onChange={(e) => setters[filter](e.target.value)}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </ul>
            <div className="absolute bottom-2 left-2 m-4 shadow-lg">
              <button className="bg-Blue shadow-lg text-base text-white rounded-md focus:outline-none px-4 py-3 md:px-4 md:py-2 sm:px-5 sm:py-2 flex items-center justify-center transform transition-transform duration-500 ease-in-out hover:scale-105 space-x-2">
                <span>Get Results</span>
              </button>
            </div>
            <div className="absolute bottom-2 right-2 flex items-center m-4">
              <button className="">
                <span className="tracking-widest text-gray-500 underline font-bold focus:outline-none px-4 py-3 md:px-6 md:py-3 sm:px-5 sm:py-2 flex items-center justify-center transform transition-transform duration-500 ease-in-out hover:scale-105 space-x-2">
                  Remove Filters
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
      {showProfile && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6">
            <button onClick={() => setShowProfile(false)}>Back</button>
            <CreatorDiscoveryProfile selectedRow={selectedRow} />
          </div>
        </div>
      )}
    </div>
  );
}

export default CreatorDiscoverySearch;
