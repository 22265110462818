/* eslint-disable no-useless-escape */
import React, { useState, useRef, useEffect } from "react";
import { useAuth } from "../../Context/AuthContext";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import axios from "axios";
import "react-phone-input-2/lib/style.css";
import AccountCreation from "./AccountCreation";
import success from "../../Assets/GirlWithEggs.png";
import { BrandService } from "../../Service/BrandServices";
import toast, { Toaster } from "react-hot-toast";
import { Mixpanel } from "../../mixpanel";
import useS3Uploader from "../../hooks/useS3Uploader";
import Loader from "../Loader";
import CustomButton from "../../utils/customButton";
const re =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const usernameRe = /^[a-z0-9_\.]+$/;
function BrandOnboardingSignUp() {
  const userEmail = useRef("");
  const userPassword = useRef("");
  const userPasswordConfirm = useRef("");
  const brandName = useRef("");
  const firstName = useRef("");
  const lastName = useRef("");
  const history = useHistory();
  const { signup } = useAuth();
  const [error, setError] = useState({
    email: "",
    password: "",
    confirmPass: "",
    firstname: "",
    lastname: "",
    brandname: "",
    phoneNumber: "",
    common: "",
    terms: "",
  });
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [email, setEmail] = useState("");
  const [isCompanyEmail, setIsCompanyEmail] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [imgUploaded, setImgUpload] = useState(false);
  const fileRef = useRef(null);
  var new_user = {};
  const { uploadFile, isLoading: isS3Loading } = useS3Uploader();

  const onChange = (e) => {
    setError({ ...error, [e.target.name]: "" });
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      console.log("file: ", e.target.files[0]);
      setImgUpload(true);
    } else {
      setImgUpload(false);
    }
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };
  useEffect(() => {
    // Define the list of non-allowed domains
    const nonCompanyDomains = [
      "gmail.com",
      "yahoo.com",
      "hotmail.com",
      "outlook.com",
      "aol.com",
    ];

    // Regular expression for validating an email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Extract the domain from the email
    const domain = email.split("@")[1];

    // Check if the email is in a valid format and the domain is not in the list of non-allowed domains
    if (
      emailRegex.test(email) &&
      domain &&
      !nonCompanyDomains.includes(domain)
    ) {
      setIsCompanyEmail(true);
    } else {
      setIsCompanyEmail(false);
    }
  }, [email]);

  const handleSubmit = async () => {
    let err = { ...error }; // Create a copy of the error object
    if (!isCompanyEmail) {
      alert("Please use a company email.");
      return;
    }

    if (userPassword.current.value !== userPasswordConfirm.current.value) {
      err.password = "Passwords do not match.";
      return setError(err);
    } else if (userPassword.current.value.length < 6) {
      err.password = "Password must be at least 6 characters long.";
      return setError(err);
    } else if (!re.test(userEmail.current.value)) {
      err.email = "Email is not valid.";
      return setError(err);
    }

    if (!userPassword.current.value) {
      err.password = "Password is required";
      return setError(err);
    }
    if (brandName.current.value === "") {
      err.brandname = "Brand name is required";
      return setError(err);
    }
    if (firstName.current.value === "") {
      err.firstname = "First name is required";
      return setError(err);
    }
    if (lastName.current.value === "") {
      err.lastname = "Last name is required";
      return setError(err);
    }
    if (!userPasswordConfirm.current.value) {
      err.confirmPass = "Confirm Password is required";
      return setError(err);
    } else if (userPassword.current.value.length < 6) {
      err.confirmPass = "Password should be at least 6 characters";
      return setError(err);
    } else if (
      userPassword.current.value !== userPasswordConfirm.current.value
    ) {
      err.confirmPass = "Passwords do not match";
      return setError(err);
    }

    if (!isTermsChecked) {
      err.terms = "You must agree to the terms of service";
      return setError(err);
    }

    try {
      setError({
        email: "",
        password: "",
        confirmPass: "",
        brandname: "",
        firstname: "",
        lastname: "",
        phoneNumber: "",
        common: "",
        terms: "",
      });
      setLoading(true);

      const currEmail = userEmail.current.value;
      const currUser = brandName.current.value.toLowerCase();

      // File upload logic
      const selectedFile = fileRef.current.files[0];
      const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];

      if (!selectedFile) {
        console.log("No file selected.");
        setLoading(false);
        return; // Aborting the upload if no file is selected
      }

      if (!allowedFileTypes.includes(selectedFile.type)) {
        console.log("Please select a JPEG, JPG, or PNG file.");
        setLoading(false);
        return; // Aborting the upload if the selected file type is not allowed
      }
      console.log("img uploaded:", imgUploaded);

      let uploadedFileLocation;

      try {
        // Generating a unique filename using the current timestamp
        const uniqueFilename =
          Date.now() + "_" + selectedFile.name.replace(/\s/g, "_");

        const uploadResult = await uploadFile(
          selectedFile,
          false,
          uniqueFilename,
        );
        uploadedFileLocation = uploadResult.Location;
      } catch (uploadError) {
        console.error("File upload failed:", uploadError);
        setLoading(false);
        return;
      }

      await signup(userEmail.current.value, userPassword.current.value.trim())
        .then(async (userCreds) => {
          const new_user = {
            uid: userCreds.user.uid,
            avatar:
              uploadedFileLocation ||
              "https://crafted-react-images.s3.amazonaws.com/default_pfp_1384719047375875089754832059.png",
            brand_name: currUser,
            first_name: firstName.current.value,
            last_name: lastName.current.value,
            email: currEmail,
            brand_website: "https://www." + currEmail.split("@")[1],
          };
          await BrandService.AddTrialBrand(new_user);
          Mixpanel.signup(new_user);
          history.push("/brand/dashboard");
          window.location.reload();
        })
        .catch((error) => {
          toast.error(`Account already found with this email. Login instead.`);
          setLoading(false);
        });
    } catch (__error) {
      err.common = __error;
      setError(err);
    }
  };

  return (
    <div className="">
      {currentTab === 0 ? (
        <div>
          <Toaster />
          <div className="">
            <label className="text-darkBlack 2xl:text-base md:text-0.7 font-bold capitalize">
              Brand Name
            </label>
            <input
              className="focus:outline-none w-full bg-lightGray 2xl:p-2 2xl:mt-2 md:p-1 md:mt-1 rounded"
              name="userName"
              onChange=""
              type="text"
              ref={brandName}
              required
            />
            {error && error.brandname && (
              <span className="text-sm text-Red">{error.brandname}</span>
            )}
          </div>
          <div className="flex gap-3">
            <div className="mb-3">
              <label className="text-darkBlack 2xl:text-base md:text-0.7 font-bold capitalize">
                First Name
              </label>
              <input
                className="focus:outline-none w-full bg-lightGray 2xl:p-2 2xl:mt-2 md:p-1 md:mt-1 rounded"
                name="userName"
                onChange=""
                type="text"
                ref={firstName}
                required
              />
              {error && error.firstname && (
                <span className="text-sm text-Red">{error.firstname}</span>
              )}
            </div>
            <div className="mb-3">
              <label className="text-darkBlack 2xl:text-base md:text-0.7 font-bold capitalize">
                Last Name
              </label>
              <input
                className="focus:outline-none w-full bg-lightGray 2xl:p-2 2xl:mt-2 md:p-1 md:mt-1 rounded"
                name="userName"
                onChange=""
                type="text"
                ref={lastName}
                required
              />
              {error && error.lastname && (
                <span className="text-sm text-Red">{error.lastname}</span>
              )}
            </div>
            <div className="mb-3">
              <label className="text-darkBlack 2xl:text-base md:text-0.7 font-bold capitalize">
                Company Photo
              </label>
              <input
                id="imageInput"
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                ref={fileRef}
                className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
                onChange={handleChange}
              />
              {error && error.companyPhoto && (
                <span className="text-sm text-Red">{error.companyPhoto}</span>
              )}
            </div>
          </div>
          <div className="mb-3">
            <label className="text-darkBlack 2xl:text-base md:text-0.7 font-bold capitalize">
              Company Email
            </label>
            <input
              className="focus:outline-none w-full bg-lightGray 2xl:p-2 2xl:mt-2 md:p-1 md:mt-1 rounded"
              name="email"
              onChange={onEmailChange}
              type="email"
              ref={userEmail}
              required
            />
            {/* Display a message if the email is not a company email */}
            {!isCompanyEmail && email && (
              <span className="text-sm text-Red">
                Please use a company email.
              </span>
            )}
          </div>
          <div className="mb-3">
            <label className="text-darkBlack 2xl:text-base md:text-0.7 font-bold capitalize">
              Password
            </label>
            <input
              className="focus:outline-none w-full bg-lightGray 2xl:p-2 2xl:mt-2 md:p-1 md:mt-1 rounded"
              name="password"
              onChange={onChange}
              type="password"
              ref={userPassword}
              required
            />
            {error && error.password && (
              <span className="text-sm text-Red">{error.password}</span>
            )}
          </div>
          <div className="mb-10">
            <label className="text-darkBlack 2xl:text-base md:text-0.7 font-bold capitalize">
              Confirm Password
            </label>
            <input
              className="focus:outline-none w-full bg-lightGray 2xl:p-2 2xl:mt-2 md:p-1 md:mt-1 rounded"
              name="confirmPass"
              onChange={onChange}
              type="password"
              ref={userPasswordConfirm}
              required
            />
            {error && error.confirmPass && (
              <span className="text-sm text-Red">{error.confirmPass}</span>
            )}
          </div>
          <div className=" ml-1">
            <input
              type="checkbox"
              id="isTermsChecked"
              name="isTermsChecked"
              value={isTermsChecked}
              onChange={(e) => setIsTermsChecked(e.target.checked)}
            />
            <span className="mx-2 hover:text-black relative">
              I acknowledge that I have read and accepted the{" "}
              <a
                className="text-Blue underline"
                target="_blank"
                href="https://crafted-react-images.s3.amazonaws.com/Client Terms of Services.docx.pdf"
              >
                Terms of Service
              </a>
            </span>
            <p className="mt-1">
              {error && error.terms && (
                <span className="text-sm text-Red">{error.terms}</span>
              )}
            </p>
          </div>

          <p>{error && error.common && error.common}</p>
          <CustomButton
            className="text-xl font-bold my-3 w-full mx-auto text-white py-2 px-14 block"
            type="button"
            disabled={loading}
            onClick={() => handleSubmit()}
          >
            {loading && <Loader />}
            Join as Brand
          </CustomButton>
        </div>
      ) : (
        currentTab === 1 && (
          <div>
            <img src={success} alt="logo" className="mx-auto h-48" />
            <div>
              <h2 className="text-4xl text-center font-bold mb-4">
                Congrats! You're in.
              </h2>
              {/*<p className="text-lg text-center font-bold text-black mb-2">
                Here are our community guidelines, to make sure
                <br /> this is a fun and safe environment to celebrate food.
              </p>
              <p className="text-lg text-Red font-bold text-center mb-2">Be respectful.</p>
              <p className="text-lg text-Red font-bold text-center mb-2">Be encouraging.</p>
              <p className="text-lg text-Red font-bold text-center mb-2">Be curious.</p>*/}
              <Link
                className="text-xl text-center font-bold my-3 w-full mx-auto bg-Blue rounded-md text-white py-2 px-14 block"
                to="/brand/dashboard"
              >
                Let's Go!
              </Link>
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default BrandOnboardingSignUp;
