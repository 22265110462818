import React from "react";
import "react-responsive-modal/styles.css";
import { ImCross } from "react-icons/im";

function EditCampaignInfo({ campaign, updateCampaign, goToNextTab }) {
  const handleNameChange = (event) => {
    updateCampaign("campaignName", event.target.value);
  };
  console.log("EDIT CAMPAIGN INFOSSSS", campaign);
  return (
    <div>
      <h1 className="font-bold ml-4">Edit Campaign</h1>
      <form onSubmit="" className="px-4 mt-4 md:mt-8">
        <div className="my-2 mt-2 md:mt-4">
          <label htmlFor="campaignName" className="text-black md:text-lg mb-2">
            Campaign name
          </label>
          <input
            name="campaignName"
            className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
            placeholder=""
            required
            maxLength="30"
            onChange={(e) => updateCampaign("campaignName", e.target.value)}
            value={campaign && campaign.campaignName}
          />
        </div>
        <div className="my-2 mt-2 md:mt-4">
          <label htmlFor="campphoto" className="text-black md:text-lg mb-2">
            Campaign Photo
          </label>
          {campaign && campaign.campaignPhoto === "" && (
            <input
              name="campphoto"
              className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
              placeholder=""
              required
              type="file"
              accept="image/png, image/jpeg, image/jpg, image/webp"
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  const file = e.target.files[0];
                  const imageUrl = URL.createObjectURL(file);
                  updateCampaign("campaignPhoto", imageUrl); // Update using the updateCampaign function
                }
              }}
            />
          )}
          {campaign && campaign.campaignPhoto !== "" && (
            <div className="w-32 relative mt-4">
              <img
                src={campaign && campaign.campaignPhoto}
                alt="Campaign"
                className="object-cover rounded-md shadow-md border-2"
              />
              <div
                className="absolute -top-3 -right-5 cursor-pointer"
                onClick={() => updateCampaign("campaignPhoto", "")}
              >
                <ImCross className="text-red" title="Remove photo" />
              </div>
            </div>
          )}
        </div>

        <div className="my-2 mt-2 md:mt-4">
          <label htmlFor="budget" className="text-black  md:text-lg mb-2">
            Influencer Reach Budget
          </label>
          <div className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full">
            <span className="mr-2 font-bold text-slate-700">$</span>
            <input
              name="budget"
              className="outline-none bg-gray-100"
              placeholder=""
              required
              type="number"
              onChange={(e) => {
                const dollarValue = e.target.value;
                const centValue = Math.round(parseFloat(dollarValue) * 100);
                updateCampaign("influencerBudget", centValue); // Update using the updateCampaign function
              }}
              value={campaign && campaign.influencerBudget / 100} // Display the value in dollars
            />
          </div>
        </div>

        <div className="flex justify-center mt-4 md:mt-8 mb-2 md:mb-4">
          <button
            onClick={() => goToNextTab()}
            className="py-2 bg-red  w-1/2 md:w-1/3 px-4 mb-5 rounded-lg text-white mt-4 justify-center cursor-pointer"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditCampaignInfo;
