import React, { useState, useEffect } from "react";
import { useAuth } from "../../Context/AuthContext";
import GlobalDialog from "../../utils/globalDialog";
import { UsersService, useUserState } from "../../Service/userServices";
import { toast, Toaster } from "react-hot-toast";
import Collapsible from "react-collapsible";
import { BsChevronCompactDown, BsChevronCompactUp } from "react-icons/bs";
import {
  CampaignService,
  useCampaignState,
} from "../../Service/CampaignServices";
import { FaRegCopy } from "react-icons/fa";
import { CampaignEmailService } from "../../Service/CampaignEmailService";
import { Mixpanel } from "../../mixpanel";
import CustomButton from "../../utils/customButton";
import { SecurityService, useSecurityState } from "../../Service/securityService";

const { REACT_APP_INSTAGRAM_REDIRECT_URI_PROD, REACT_APP_FACEBOOK_API_URL } =
  process.env;

function InfluencerSubmitLink(props) {
  const { task, open, onClose } = props;
  const { currentUser } = useAuth();
  const userState = useUserState();

  const [tiktokLink, setTikTokLink] = useState(""); // Input field state for post link
  const [instagramLink, setInstagramLink] = useState(""); // Input field state for post link
  const [instagramError, setInstagramError] = useState("");
  const [tiktokError, setTiktokError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [copied, setCopied] = useState(false);
  const campaignState = useCampaignState();

  const { secretKeys } = useSecurityState().get();

  useEffect(() => {
    // Simulating an API call or any asynchronous task
    const getSecretKey = async () => {
      try {
        await SecurityService.getSecretKey({
          name: "REACT_APP_FACEBOOK_APP_CLIENT_ID",
        });
      } catch (error) {
        console.error("Failed to get TikTok's client key.", error);
      }
    };

    getSecretKey();
  }, []);

  useEffect(() => {
    if (userState.updateNeed.value) {
      UsersService.FetchUserInfo(currentUser.uid);
    }
  }, [userState.updateNeed.value]);

  const user = userState.user.value;
  const creatorId = currentUser.uid;
  const matchingProposals = task.approved_proposals[creatorId];
  const proposalKey = Object.keys(matchingProposals)[0]; // Assuming there is only one proposal for the creatorId
  const matchingProposal = matchingProposals[proposalKey];
  const instagramLinkPattern =
    /^https:\/\/www\.instagram\.com\/reel\/[a-zA-Z0-9_-]+\/?$/;
  const tiktokLinkPattern =
    /^https:\/\/www\.tiktok\.com\/@[\w.-]+\/video\/\d+(\/)?(\?.*)?$/;

  const startInstagramLoginFlow = () => {
    const clientId = secretKeys["REACT_APP_FACEBOOK_APP_CLIENT_ID"];
    const scope =
      "instagram_basic,instagram_manage_insights,pages_show_list,pages_read_engagement,business_management";
    const display = "page";
    const extras = { setup: { channel: "IG_API_ONBOARDING" } };
    const responseType = "token";
    const authUrl = `${REACT_APP_FACEBOOK_API_URL}/v18.0/dialog/oauth?client_id=${clientId}&display=${display}&extras=${extras}&redirect_uri=${REACT_APP_INSTAGRAM_REDIRECT_URI_PROD}&response_type=${responseType}&scope=${scope}`;

    window.location.href = authUrl;
  };

  const isTikTokLinkValid = (link) => {
    return tiktokLinkPattern.test(link);
  };

  const isInstagramLinkValid = (link) => {
    return instagramLinkPattern.test(link);
  };

  useEffect(() => {
    if (!isTikTokLinkValid(tiktokLink))
      setTiktokError(
        "Invalid TikTok link, make sure you are using the desktop share link.",
      );
    else setTiktokError("");
  }, [tiktokLink]);

  useEffect(() => {
    if (!isInstagramLinkValid(instagramLink))
      setInstagramError(
        "Invalid Instagram link, make sure you are using a valid link.",
      );
    else setInstagramError("");
  }, [instagramLink]);

  const handleCopy = () => {
    const link = task.approved_proposals[currentUser.uid].short_link;
    navigator.clipboard
      .writeText(link)
      .then(() => setCopied(true))
      .catch((error) => console.error("Error copying link: ", error));
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const isFormValid = () =>
    isInstagramLinkValid(instagramLink) && isTikTokLinkValid(tiktokLink);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    setInstagramError("");
    setTiktokError("");

    // Theres's no way this can trigger but adding this just in case.
    if (!isFormValid) {
      toast.error("Please submit valid links according to the examples.");
      return;
    }

    try {
      const posts = [];

      if (tiktokLink) {
        posts.push({
          social_media_link: `${tiktokLink.split("?")[0]}`,
          platform: "tiktok",
        });
      }

      if (instagramLink) {
        posts.push({
          social_media_link: `${instagramLink.split("?")[0]}`,
          platform: "instagram",
        });
      }

      if (posts.length) {
        let details = {
          campaign_id: task.campaign_id,
          creator_id: currentUser.uid,
          brand_id: task.brand_id,
          proposal_id: proposalKey,
          creator_name: user.shipping_details.fullname,
          add_ons: task && task.add_ons,
          short_link: task.approved_proposals[creatorId].short_link
            ? task.approved_proposals[creatorId].short_link
            : "",
          posts: posts,
        };

        const emailData = {
          brand_id: task.brand_id,
          creator_id: currentUser.uid,
          task_id: task.id,
        };

        const mixpanel_task = {
          brand_id: task.brand_id,
          brand_name: task.brand_name,
          campaign_id: task.campaign_id,
          //'campaign_name': task.campaign_name,
          id: task.id,
          name: task.name,
          platform: task.platform,
        };

        await CampaignService.AddPost(task.id, details);
        await CampaignEmailService.SendTaskCompletionEmail(emailData);

        Mixpanel.influencerPostAdded(mixpanel_task);
        campaignState.merge({ loading: false });
        toast.success("Post added successfully.");
        setTimeout(() => {
          window.location.reload();
        }, 1300);
      }
    } catch (error) {
      toast.error(
        "Error adding post. Try reconnecting your Instagram/TikTok account or ensure that the links you provided belong to the correct accounts.",
      );
    }

    setIsSubmitting(false);
  };

  console.log(matchingProposal);

  return (
    <div className="break-words">
      <Toaster />
      <GlobalDialog className="p-2 h-fit" open={open} onClose={onClose}>
        <div className="m-3 bg-white rounded-lg text-3xl dark:bg-gray-700">
          <Collapsible
            trigger={[
              "Submit Link",
              <div key="closed" className="px-3 absolute right-0">
                <BsChevronCompactDown />
              </div>,
            ]}
            triggerWhenOpen={[
              "Submit Link",
              <div key="opened" className="px-3 absolute right-0">
                <BsChevronCompactUp />
              </div>,
            ]}
            contentOuterClassName=""
            triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            triggerOpenedClassName={
              "flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            }
          >
            <div className="m-3 bg-white rounded-lg text-3xl dark:bg-gray-700"></div>
          </Collapsible>

          <form onSubmit={handleSubmit}>
            <div className="flex-wrap">
              <div className="py-3">
                <div className="text-base font-bold ">
                  Please copy & paste the full share link to your TikTok or
                  Instagram Post here.
                </div>
                <div className="text-gray-500 break-words text-base italic">
                  Example Links:
                  <div>
                    <span>
                      {" "}
                      &apos;https://www.tiktok.com/@exampleuser/video/7257184846689176875&apos;
                    </span>
                  </div>
                  <div>
                    <span>
                      &apos;https://www.instagram.com/reel/Cy2GCUIOFr4/&apos;
                    </span>
                  </div>
                </div>
                {task.platform &&
                  task.platform.includes("TikTok") &&
                  matchingProposal.creator_socials &&
                  matchingProposal.creator_socials.tiktok && (
                    <div className="py-3">
                      <div>TikTok Link:</div>
                      <input
                        type="text"
                        placeholder="Enter your TikTok Link here"
                        name="link"
                        required
                        className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                        value={tiktokLink}
                        onChange={(e) => setTikTokLink(e.target.value)}
                      />

                      {tiktokError && tiktokLink && (
                        <p className="text-red md:ml-2 mt-2">{tiktokError}</p>
                      )}
                      <div className="mt-2 ml-1">
                        <span className="font-bold">IMPORTANT</span>: For
                        TikTok, use a desktop device to retrieve the share link
                        of your post.
                      </div>
                    </div>
                  )}

                {task.platform &&
                  task.platform.includes("Instagram") &&
                  matchingProposal.creator_socials &&
                  matchingProposal.creator_socials.instagram && (
                    <div className="py-3">
                      <div>Instagram Link:</div>
                      <input
                        type="text"
                        placeholder="Enter your Instagram Link here"
                        name="link"
                        required
                        className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                        value={instagramLink}
                        onChange={(e) => setInstagramLink(e.target.value)}
                      />
                      {instagramError && instagramLink && (
                        <p className="text-red md:ml-2 mt-2">
                          {instagramError}
                        </p>
                      )}
                    </div>
                  )}
                {task.approved_proposals[creatorId] &&
                  task.approved_proposals[creatorId].short_link && (
                    <div className="flex flex-col items-start">
                      <div className="flex flex-row items-center">
                        <input
                          type="checkbox"
                          required
                          id="bioAgreement"
                          name="bioAgreement"
                        />
                        <h3 className="ml-2">
                          I have added this link in my bio and will keep it
                          there for 30 days:
                        </h3>
                      </div>
                      <div className="flex items-center ml-4">
                        <a
                          href={task.approved_proposals[creatorId].short_link}
                          target="_blank"
                          className="text-blue font-bold underline mr-2"
                        >
                          {task.approved_proposals[creatorId].short_link}
                        </a>
                        <button onClick={handleCopy}>
                          <FaRegCopy title="Copy link" />
                        </button>
                        {copied && (
                          <span className="ml-2 text-green-500">
                            Link copied!
                          </span>
                        )}
                      </div>
                    </div>
                  )}
              </div>
            </div>

            <div className="items-center justify-center text-center">
              {task.platform &&
              task.platform.includes("Instagram") &&
              matchingProposal &&
              matchingProposal.creator_socials &&
              matchingProposal.creator_socials.instagram &&
              user &&
              user.creator_socials &&
              user.creator_socials.instagram &&
              !user.creator_socials.instagram.access_token ? (
                <div className="message text-red">
                  <p>
                    You have not connected your Instagram account using OAuth.
                  </p>
                  <p>Please click below to connect.</p>
                  <CustomButton
                    onClick={() => startInstagramLoginFlow()}
                    className="mt-3 text-white w-1/3 py-4 outline-none text-base"
                  >
                    Connect Instagram
                  </CustomButton>
                </div>
              ) : (
                <CustomButton
                  className="bg-red text-white w-1/3 py-4 outline-none text-base"
                  type="submit"
                  disabled={isSubmitting || !isFormValid} // This line disables the button if isSubmitting is true
                >
                  {isSubmitting ? (
                    <span>
                      <span className="mr-2">Submitting...</span>
                    </span>
                  ) : (
                    "Submit Link(s)"
                  )}
                </CustomButton>
              )}
            </div>
          </form>
        </div>
      </GlobalDialog>
    </div>
  );
}

export default InfluencerSubmitLink;
